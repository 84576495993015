<template>
  <router-view/>
</template>

<style>
*, :after, :before {
  box-sizing: border-box
}

body {
  transition: 1s;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #212121;
  color: #fff;
  font-family: monospace, serif;
  letter-spacing: .05em
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

h1 {
  font-size: 23px
}

.form {
  width: 70%;
  padding: 64px 15px 24px;
  margin: 0 auto
}

.form .control {
  margin: 0 0 24px
}

.form .control input {
  width: 100%;
  padding: 14px 16px;
  border: 0;
  background: transparent;
  color: #fff;
  font-family: monospace, serif;
  letter-spacing: .05em;
  font-size: 16px
}

.form .btn, .form .control input:focus, .form .control input:hover {
  outline: none;
  border: 0
}

.form .btn {
  width: 100%;
  display: block;
  padding: 14px 16px;
  background: transparent;
  color: #fff;
  letter-spacing: .1em;
  font-weight: 700;
  font-family: monospace;
  font-size: 16px
}

.block-cube {
  position: relative
}

.block-cube .bg-top {
  position: absolute;
  height: 10px;
  background: #020024;
  background: linear-gradient(90deg, #020024, #340979 37%, #3d5c64 94%);
  bottom: 100%;
  left: 5px;
  right: -5px;
  transform: skew(-45deg, 0);
  margin: 0
}

.block-cube .bg-top .bg-inner {
  bottom: 0
}

.block-cube .bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #020024;
  background: linear-gradient(90deg, #020024, #340979 37%, #1c91a4 94%)
}

.block-cube .bg-right {
  position: absolute;
  background: #00d4ff;
  top: -5px;
  z-index: 0;
  bottom: 5px;
  width: 10px;
  left: 100%;
  transform: skew(0, -45deg)
}

.block-cube .bg-right .bg-inner {
  left: 0
}

.block-cube .bg .bg-inner {
  transition: all .2s ease-in-out
}

.block-cube .bg-inner {
  background: #212121;
  position: absolute;
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px
}

.block-cube .text, .block-cube.block-input input {
  position: relative;
  z-index: 2
}

.block-cube.block-input input:focus ~ .bg-inner .bg-inner, .block-cube.block-input input:focus ~ .bg-right .bg-inner, .block-cube.block-input input:focus ~ .bg-top .bg-inner {
  top: 100%;
  background: hsla(0, 0%, 100%, .5)
}

.block-cube.block-input .bg, .block-cube.block-input .bg-right, .block-cube.block-input .bg-top {
  background: hsla(0, 0%, 100%, .5);
  transition: background .2s ease-in-out
}

.block-cube.block-input .bg-right .bg-inner, .block-cube.block-input .bg-top .bg-inner {
  transition: all .2s ease-in-out
}

.block-cube.block-input:focus .bg, .block-cube.block-input:focus .bg-right, .block-cube.block-input:focus .bg-top, .block-cube.block-input:hover .bg, .block-cube.block-input:hover .bg-right, .block-cube.block-input:hover .bg-top {
  background: hsla(0, 0%, 100%, .8)
}

.block-cube.block-cube-hover:focus .bg .bg-inner, .block-cube.block-cube-hover:hover .bg .bg-inner {
  top: 100%
}

.body-complete {
  transition: .5s;
  background-color: #3a604c
}

.body-error {
  transition: .5s;
  background-color: #492727
}
</style>
