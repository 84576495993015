<template>
  <div>
    <h2 style="text-decoration: underline;" @click="this.$router.push('/product')">
      Upload product
    </h2>
  </div>
  <h1 style="font-size: 40px; text-align: center">
    {{profile_data.username}}
  </h1>
  <table class="form">
    <tr>
      <th>Wallet</th>
      <th>Address</th>
      <th></th>
    </tr>
    <template v-for="wallet_model in available_wallet">
      <tr>
        <td>{{ wallet_model.title }}</td>
        <td>
          <input
              type="text"
              style="width: 100%;  outline: none; border: none; box-shadow: none; background-color: #2d2d2d; border-radius: 2px; color: white"
              v-model="profile_data.wallets[wallet_model.title]"
          >
        </td>
        <td>
          <CheckIcon @click="UpdateWallet(wallet_model.id, wallet_model.title)"/>
        </td>
      </tr>
    </template>
  </table>

  <table class="form payout-table">
    <tr>
      <th>Amount</th>
      <th>TxID</th>
      <th>Date</th>
      <th></th>
    </tr>
    <template v-for="payout_model in payouts">
      <ProfilePayout :payout_model="payout_model"/>
    </template>

  </table>
</template>


<script>
import axios from "axios";
import CheckIcon from 'vue-material-design-icons/Check.vue';
import ProfilePayout from '@/views/ProfilePayout.vue'

export default {
  components: {
    CheckIcon,
    ProfilePayout
  },
  data() {
    return {
      profile_data: {},
      available_wallet: [],
      payouts: [],

      wallet_settings: {}
    };
  },
  mounted() {
    this.GetInfoMe()
    this.GetAvailableWallet()
    this.GetPayouts()
  },
  methods: {
    GetInfoMe() {
      axios.get(this.$store.state.siteDomain + '/api/info/me')
          .then((response) => {
                console.log(response.data)
                this.profile_data = response.data
              }
          )
    },
    GetAvailableWallet() {
      axios.get(this.$store.state.siteDomain + '/api/info/wallets')
          .then((response) => {
                console.log(response.data)
                this.available_wallet = response.data
              }
          )
    },
    GetPayouts() {
      axios.get(this.$store.state.siteDomain + '/api/profile/payout')
          .then((response) => {
                this.payouts = response.data
              }
          )
    },
    UpdateWallet(key_wallet, name_wallet) {
      if (this.profile_data.wallets[name_wallet]) {
        axios.post(
            this.$store.state.siteDomain + '/api/profile/wallet',
            {wallet_id: key_wallet, wallet_address: this.profile_data.wallets[name_wallet]}
        )
            .then((response) => {
                  this.GetInfoMe()
                }
            )
      }

    }
  }
}
</script>


<style>
.payout-table {
  text-align: left;
  border-collapse: collapse;
}
.payout-table tr td {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #3a3a3a;
}
</style>
