import { createRouter, createMemoryHistory } from 'vue-router'
import Login from '../views/Login.vue'
import InsertProduct from '../views/InsertProduct.vue'
import Profile from '../views/Profile.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/product',
    name: 'product',
    component: InsertProduct
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  }
]

const router = createRouter({
  history: createMemoryHistory(process.env.BASE_URL),
  routes
})

export default router
