<template>
  <div style="display: flex; flex-direction: row-reverse;">
    <h2 style="text-decoration: underline;" @click="this.$router.push('/profile')">
      Profile
    </h2>
  </div>
  <form
        class="form"
        autocomplete="off"
        method="post"
        action="/api/product"
        enctype="multipart/form-data">
    <div class="control">
      <h1 style="font-size: 40px; text-align: center">
        Upload Product
      </h1>
    </div>
    <div class="control block-cube" :class="regionChoiceId ? 'block-input-complete':'block-input'">
      <select
          class="select"
          name="region"
          @change="regionChoiceId=Number($event.target.value)"
      >
        <option :value="0">
          Select Region
        </option>
        <template v-for="region_model in region_list">
          <option
              :value="region_model.id"
              :selected="regionChoiceId === region_model.id"
          >
            {{ region_model.title }}
          </option>
        </template>

      </select>
      <div class="bg-top"><div class="bg-inner"></div></div>
      <div class="bg-right"><div class="bg-inner"></div></div>
    </div>
    <div
        class="control block-cube block-half"
        style="margin-right:2%;"
        :class="cityChoiceId ? 'block-input-complete':'block-input'"
    >
      <select
          class="select"
          name="city"
          :disabled="regionChoiceId === 0 || city_list === []"
          @change="cityChoiceId=Number($event.target.value)"
      >
      <option value="0">Select City</option>
        <template v-for="city_model in city_list">
          <option
              :value="city_model.id"
              :selected="cityChoiceId === city_model.id"
          >
            {{ city_model.title }}
          </option>
        </template>
    </select>
      <div class="bg-top"><div  class="bg-inner"></div></div>
      <div  class="bg-right"><div  class="bg-inner"></div></div>
    </div>
    <div
        class="control block-cube block-half"
        :class="districtChoiceId ? 'block-input-complete':'block-input'"
    >
      <select
          class="select"
          name="district_id"
          :disabled="cityChoiceId === 0 || district_list === []"
          @change="districtChoiceId=Number($event.target.value)"
      >
      <option>Select District</option>
        <template v-for="district_model in district_list">
          <option
              :value="district_model.id"
              :selected="districtChoiceId === district_model.id"
          >
            {{ district_model.title }}
          </option>
        </template>
    </select>
      <div class="bg-top"><div class="bg-inner"></div></div>
      <div class="bg-right"><div class="bg-inner"></div></div>
    </div>
    <div
        class="control block-cube"
        style="margin-top: 25px"
        :class="categoryChoiceId ? 'block-input-complete':'block-input'"
    >
      <select
          class="select"
          name="substance_id"
          @change="categoryChoiceId=Number($event.target.value)"
      >
        <option :value="0">Select Category</option>
        <template v-for="category_model in category_list">
          <option
              :value="category_model.id"
              :selected="categoryChoiceId === category_model.id"
          >
            {{ category_model.title }}
          </option>
        </template>
    </select>
      <div class="bg-top"><div class="bg-inner"></div></div>
      <div class="bg-right"><div class="bg-inner"></div></div>
    </div>
    <div
        class="control block-cube block-half"
        style="margin-right:2%;"
        :class="subcategoryChoiceId ? 'block-input-complete':'block-input'"
    >
      <select
          class="select"
          name="subcategory_id"
          :disabled="categoryChoiceId === 0"
          @change="subcategoryChoiceId=Number($event.target.value)"
      >
        <option :value="0">Select Subcategory</option>
        <template v-for="subcategory_model in subcategory_list">
          <option
              :value="subcategory_model.id"
          >
            {{ subcategory_model.title }}
          </option>
        </template>
      </select>
      <div class="bg-top"><div class="bg-inner"></div></div>
      <div class="bg-right"><div class="bg-inner"></div></div>
    </div>
    <div
        class="control block-cube block-half"
        :class="substanceChoiceId ? 'block-input-complete':'block-input'"
    >
      <select
          class="select"
          name="pack_id"
          :disabled="subcategoryChoiceId === 0"
          @change="substanceChoiceId=Number($event.target.value)"
      >
        <option :value="0">Select Pack</option>
        <option v-for="substance_model in substance_list"
                :value="substance_model.id"
                :selected="substanceChoiceId === substance_model.id"
        >
          {{ substance_model.mass }} {{ substance_model.mass_abbreviation }}
        </option>
      </select>
      <div class="bg-top"><div class="bg-inner"></div></div>
      <div class="bg-right"><div class="bg-inner"></div></div>
    </div>
    <div
        class="control block-cube "
        :class="description ? 'block-input-complete':'block-input'"
    >
      <textarea
          name="description"
          id=""
          cols="30"
          rows="10"
          placeholder="Description"
          v-model="description"
      ></textarea>
      <div class="bg-top"><div class="bg-inner"></div></div>
      <div class="bg-right"><div class="bg-inner"></div></div>
    </div>
    <div>
      <div
          class="control block-cube block-input block-half"
          style="margin-right: 2%;"
          :class="latitude < 90 && latitude > -90 ? latitude ? 'block-input-complete': '' :'block-input-error'"
      >
        <input
            type="number"
            placeholder="latitude"
            required="" min="-90" max="90"
            name="latitude"
            class="no-spinners"
            v-model="latitude"
        >
        <div class="bg-top"><div class="bg-inner"></div></div>
        <div class="bg-right"><div class="bg-inner"></div></div>
        <div class="bg"><div class="bg-inner"></div></div>
      </div>
      <div
          class="control block-cube block-input block-half"
          :class="longitude < 180 && longitude > -180 ? longitude ? 'block-input-complete': '' :'block-input-error'"
      >
        <input
            type="number"
            placeholder="longitude"
            required="" min="-180" max="180"
            name="longitude"
            class="no-spinners"
            v-model="longitude"

        >
        <div class="bg-top"><div class="bg-inner"></div></div>
        <div class="bg-right"><div class="bg-inner"></div></div>
        <div class="bg"><div class="bg-inner"></div></div>
      </div>
      <div class="file-upload ">
        <div v-if="previewURLs.length > 0" style="border: 3px solid hsla(0, 0%, 100%, .5); ">
          <div v-if="previewURLs.length > 0">
            <div v-for="(url, index) in previewURLs" :key="index" style="display: inline-block; position: relative;">
              <img :src="url.image" alt="Preview" class="image-preview"/>
              <span class="close-icon" @click="deleteImage(index)">×</span>
            </div>
          </div>
        </div>
        <div
            class="image-upload-wrap"
            :class="previewURLs.length > 0 ? previewURLs.length > 2 ? '': 'file-upload-input-warn' : ''"
        >
          <input data-v-417c7de6=""
           class="file-upload-input " type="file"
           name="photos" accept="image/*" multiple @change="previewImages">
          <div class="drag-text">
            <h3>Choose image (min 3)</h3>
          </div>
        </div>
      </div>
    </div>
    <button
        class="btn block-cube block-cube-hover"
        type="button"
        :disabled="
          regionChoiceId === 0 || cityChoiceId === 0 ||
          districtChoiceId === 0 || categoryChoiceId === 0 ||
          subcategoryChoiceId === 0 || substanceChoiceId === 0 ||
          description === '' || description === null ||
          latitude === null || longitude === null ||
          previewURLs.length < 3
        "
        @click="uploadProduct"
    >
      <div class="bg-top"><div class="bg-inner"></div></div>
      <div class="bg-right"><div class="bg-inner"></div></div>
      <div class="bg"><div class="bg-inner"></div></div>
      <div class="text">Send</div>
    </button>
  </form>
</template>

<script>
import router from "@/router";
import axios from "axios";

export default {
  data() {
    return {
      previewURLs: [],
      region_list: [],
      regionChoiceId: 0,
      city_list: [],
      cityChoiceId: 0,
      district_list: [],
      districtChoiceId: 0,

      category_list: [],
      categoryChoiceId: 0,
      subcategory_list: [],
      subcategoryChoiceId: 0,
      substance_list: [],
      substanceChoiceId: 0,

      description: null,
      latitude: null,
      longitude: null
    };
  },
  mounted() {
    axios.get(this.$store.state.siteDomain + '/api/info/region')
        .then((response) => {
          console.log(response.data)
          this.region_list = response.data
        })
    axios.get(this.$store.state.siteDomain + '/api/info/category')
        .then((response) => {
          console.log(response.data)
          this.category_list = response.data
        })
  },
  watch: {
    regionChoiceId() {
      console.log('regionChoiceId')
      this.city_list = []
      this.cityChoiceId = 0
      if (this.regionChoiceId > 0) {
        this.getCity()
      }
    },
    cityChoiceId() {
      console.log('cityChoiceId')
      this.district_list = []
      this.districtChoiceId = 0
      if (this.cityChoiceId > 0) {
        this.getDistrict()
      }
    },
    categoryChoiceId(){
      console.log('cityChoiceId')
      this.subcategory_list = []
      this.subcategoryChoiceId = 0
      if (this.categoryChoiceId > 0) {
        this.getSubcategory()
      }
    },
    subcategoryChoiceId() {
      console.log('subcategoryID')
      this.substance_list = []
      this.substanceChoiceId = 0
      if (this.subcategoryChoiceId > 0) {
        this.getSubstance()
      }
    }
  },
  methods: {
    previewImages(event) {
      // this.previewURLs = [];
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
            this.previewURLs.push({
              image: e.target.result,
              file: file
            });
        }
        reader.readAsDataURL(file);
      }
    },
    deleteImage(index) {
      this.previewURLs.splice(index, 1)
    },
    getCity() {
      axios.get(this.$store.state.siteDomain + '/api/info/city',
          {params: {region_id: this.regionChoiceId}})
          .then((response) => {
            console.log(response.data)
            this.city_list = response.data
          })
    },
    getDistrict() {
      axios.get(this.$store.state.siteDomain + '/api/info/district',
          {params: {city_id: this.cityChoiceId}})
          .then((response) => {
            console.log(response.data)
            this.district_list = response.data
          })
    },
    getSubcategory() {
      axios.get(this.$store.state.siteDomain + '/api/info/subcategory',
          {params: {category_id: this.categoryChoiceId}})
          .then((response) => {
            console.log(response.data)
            this.subcategory_list = response.data
          })
    },
    getSubstance() {
      axios.get(this.$store.state.siteDomain + '/api/info/substance',
          {params: {subcategory_id: this.subcategoryChoiceId}})
          .then((response) => {
            console.log(response.data)
            this.substance_list = response.data
          })
    },
    uploadProduct() {
      var bodyElement = document.querySelector('body');
      var bodyFormData = new FormData();
      bodyFormData.append('description', this.description);
      bodyFormData.append('latitude', this.latitude);
      bodyFormData.append('longitude', this.longitude);
      bodyFormData.append('district_id', this.districtChoiceId);
      bodyFormData.append('substance_id', this.substanceChoiceId);
      for (let i = 0; i < this.previewURLs.length; i++) {
        bodyFormData.append('photos', this.previewURLs[i].file)
      }
      axios({
        method: "post",
        url: this.$store.state.siteDomain + '/api/product/',
        data: bodyFormData,
        headers: {"Content-Type": "multipart/form-data"}
      }).then((response) => {
        bodyElement.className = 'body-complete';
        this.latitude = null
        this.description = null
        this.longitude = null
        this.districtChoiceId = 0
        this.substanceChoiceId = 0
        this.previewURLs = []
        setTimeout(
            function () {bodyElement.classList.remove('body-complete')}, 400
        )
      }).catch((response) => {
        bodyElement.className = 'body-error';
        setTimeout(
            function () {bodyElement.classList.remove('body-error')}, 400
        )
      })
    }
  }
}
</script>

<style scoped>

.select {
  width: 100%;
  height: 50px;
  border: 2.7px solid;
  border-radius: 0;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #212121;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  color: hsla(0, 0%, 100%, .5);
}

.select:focus {
  outline: none !important
}

.block-cube.block-input:focus .bg-right, .block-cube.block-input:focus .bg-top, .block-cube.block-input:focus .bg, .block-cube.block-input:hover .bg-right, .block-cube.block-input:hover .bg-top, .block-cube.block-input:hover .bg {
  background: rgba(56, 126, 59, 0.25)
}

.block-cube.block-input-error .bg-right, .block-cube.block-input-error .bg-top, .block-cube.block-input-error:focus .bg, .block-cube.block-input-error:hover .bg-right, .block-cube.block-input-error:hover .bg-top, .block-cube.block-input-error:hover .bg {
  background: rgba(126, 69, 56, 0.8)
}

.block-cube.block-input-error .bg-top .bg-inner {
  background: rgba(126, 69, 56, 0.8)
}

.block-cube.block-input-error .bg-right .bg-inner {
  background: rgba(126, 69, 56, 0.8)
}

.block-cube.block-input-complete .bg-right, .block-cube.block-input-complete .bg-top, .block-cube.block-input-complete:focus .bg, .block-cube.block-input-complete:hover .bg-right, .block-cube.block-input-complete:hover .bg-top, .block-cube.block-input-complete:hover .bg {
  background: rgba(56, 126, 59, 0.25)
}

.block-cube.block-input-complete .bg-top .bg-inner {
  background: rgba(56, 126, 59, 0.25)
}

.block-cube.block-input-complete .bg-right .bg-inner {
  background: rgba(56, 126, 59, 0.25)
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.image-preview {
  width: 217px;
  height: 217px;
  margin: 10px;
  border-radius: 10px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: hsla(0, 86%, 74%, 0.5);
  color: white;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  border-radius: 50%;
}

.close-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.block-half {
  width: 49%;
  display: inline-block;
}

textarea {
  border: 2.7px solid;
  color: hsla(0, 0%, 100%, .5);
  block-size: auto;
  width: 100%;
  height: 200px;
  background-color: #212121;
  font-size: 16px
}

textarea:focus {
  outline: none !important
}


.file-upload {
  background-color: #212121;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px
}

.file-upload-input-warn {
  background-color: #492929;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
  background-color: #42b983
}
.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed hsla(0, 0%, 100%, .5);
  position: relative
}

.image-dropping, .image-upload-wrap:hover {
  background-color: hsla(0, 2%, 53%, .5);
  border: 4px dashed #fff
}

.drag-text {
  text-align: center
}

.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: hsla(0, 0%, 100%, .5)
}


.block-cube:disabled .bg-top {
  position: absolute;
  height: 10px;
  background: #46454d;
  background: linear-gradient(90deg, #232323, #7a7a7a 37%, #f5f5f5 94%);
  bottom: 100%;
  left: 5px;
  right: -5px;
  transform: skew(-45deg, 0);
  margin: 0
}

.block-cube:disabled .bg-top .bg-inner {
  bottom: 0
}

.block-cube:disabled .bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #46454d;
  background: linear-gradient(90deg, #232323, #7a7a7a 37%, #f5f5f5 94%)
}

.block-cube:disabled .bg-right {
  position: absolute;
  background: hsla(0, 0%, 100%, .8);
  top: -5px;
  z-index: 0;
  bottom: 5px;
  width: 10px;
  left: 100%;
  transform: skew(0, -45deg)
}


</style>