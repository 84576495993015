<template>
    <form class="form" autocomplete="off" @keyup.enter="SignIn">
      <div class="control"><h1> Sign In </h1></div>
      <div class="control block-cube block-input">
        <input name="username" type="text" placeholder="Username" v-model="username">
        <div class="bg-top">
          <div class="bg-inner"></div>
        </div>
        <div class="bg-right">
          <div class="bg-inner"></div>
        </div>
        <div class="bg">
          <div class="bg-inner"></div>
        </div>
      </div>
      <div class="control block-cube block-input">
        <input name="password" type="password" placeholder="Password" minlength="8" v-model="password">
        <div class="bg-top">
          <div class="bg-inner"></div>
        </div>
        <div class="bg-right">
          <div class="bg-inner"></div>
        </div>
        <div class="bg">
          <div class="bg-inner"></div>
        </div>
      </div>
      <div>
        <button class="btn block-cube block-cube-hover" type="button" @click="SignIn">
          <div class="bg-top">
            <div class="bg-inner"></div>
          </div>
          <div class="bg-right">
            <div class="bg-inner"></div>
          </div>
          <div class="bg">
            <div class="bg-inner"></div>
          </div>
          <div class="text"> Log In</div>
        </button>
      </div>
    </form>
</template>

<script>
import axios from "axios";
import router from "@/router";


export default {
  name: 'Login',
  data(){
    return {
      username: null,
      password: null,
    }
  },
  methods: {
    SignIn() {
      axios.defaults.withCredentials = true
      if (this.username !== null && this.password !== null) {
        var bodyElement = document.querySelector('body');
        axios.post(this.$store.state.siteDomain + '/api/auth/login',
          {
            username: this.username,
            password: this.password
          })
          .then((response) => {
            console.log(response)
            this.$store.state.isLogin = true
            router.push('/product')
            bodyElement.className = 'body-complete';
            setTimeout(
                function () {bodyElement.classList.remove('body-complete')}, 400
            )
          })
          .catch((response) => {
            bodyElement.className = 'body-error';
            this.password=''
            setTimeout(
                function () {bodyElement.classList.remove('body-error')}, 400
            )
          })
      }
    },
  }
}
</script>

<style scoped>


</style>