<template>
  <tr>
    <td>{{ payout_model.payout_amount }}₽</td>
    <td>{{ payout_model.txid }}</td>
    <td>{{ new Date(payout_model.created_at).toLocaleDateString() }}</td>
    <td
        @click="is_show=!is_show;
        GetPayout(payout_model.id);
        !is_show ? _rotate='rotate(180deg)' : _rotate=''"
        :style="{transform: _rotate, 'text-align': is_show ? 'right':'left'}" style="" >
    <TriangleOutlineIcon/>
    </td>
  </tr>

  <template v-if="is_show && payout_show">
    <template v-for="payout_show_model in payout_show">
      <tr class="payout-table-show">
        <td>
          {{ payout_show_model.courier_fee }}₽
        </td>
        <td>
          {{ payout_show_model.region_title }}/{{ payout_show_model.city_title }}/{{ payout_show_model.district_title }}
        </td>
        <td>
          {{ payout_show_model.category_title }}/{{ payout_show_model.subcategory_title }}
        </td>
        <td>
          {{ new Date(payout_show_model.created_at).toLocaleDateString() }}
        </td>
      </tr>
      <tr class="payout-table-show-new">
        <td colspan="4">
          <i>{{ payout_show_model.description }}</i>
        </td>
      </tr>
    </template>

  </template>
  <br>

</template>


<script>
import axios from "axios";
import TriangleOutlineIcon from 'vue-material-design-icons/TriangleOutline.vue';


export default {
  props: ['payout_model'],
  components: {
    TriangleOutlineIcon
  },
  data() {
    return {
      is_show: false,
      payout_show: [],
      _rotate: 'rotate(180deg)'
    };
  },
  mounted() {

  },
  methods: {
    GetPayout(id) {
      axios.get(this.$store.state.siteDomain + '/api/profile/payout/' + id)
        .then((response) => {
              this.payout_show = response.data
            }
        )
    }
  }
}
</script>

<style scoped>
.payout-table-show td {
  padding-top: 5px;
  padding-bottom: 0;
  background-color: #3a3a3a;
}

.payout-table-show-new td {
  padding-top: 0;
  padding-bottom: 15px;
  background-color: #3a3a3a;
  font-size: 10px
}
</style>